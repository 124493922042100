
export const boards = [
    {
        ID: 1,
        Name: 'North'
    },
    {
        ID:2,
        Name: 'South'
    },
    {
        ID:3,
        Name: 'Central'
    },
    {
        ID: 4,
        Name: 'NGL North'
    },
    {
        ID: 5,
        Name: 'NGL South'
    },
    {
        ID: 6,
        Name: 'Propane'
    },
    {
        ID: 7,
        Name: 'Brine'
    }
];

export const ticketType = [
    {
        ID: 1,
        label: 'All Tickets',
        Value: 'All'
    },
    {
        ID: 2,
        label: 'Planned Tickets',
        Value: 'Planned'
    }
]

export const driverConfigBoards = [
    {
        ID: 1,
        Value: 'All'
    },
    {
        ID: 2,
        Value: 'North'
    },
    {
        ID: 3,
        Value: 'South'
    },
    {
        ID: 4,
        Value: 'Central'
    },
    {
        ID: 5,
        Value: 'NGL North'
    },
    {
        ID: 6,
        Value: 'NGL South'
    },
    {
        ID: 7,
        Value: 'Propane'
    },
    {
        ID: 8,
        Value: 'Brine'
    }
]

export const recalledEnableArray = [
    'Driver Dispatched',
    'Driver Accepted',
    'Enroute',
    'Dispatch Pending',
    'Carrier Assigned',
    'Carrier Accepted',
    'Recalled By Carrier'
];

export const unplanEnableArray = [
    'Pending',
    'Recalled',
    'Cancelled By Driver',
    'Rejected By Driver',
    'Rejected By Carrier',
    'Cancelled By Carrier',
    'Recalled By Carrier'
]

export const unsavedMsg = 'Any unsaved information will be lost. Do you want to continue ?'