import React, { Component } from 'react';
import { GridOptions } from 'ag-grid-community';
import { Col, Container, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { localStorageService } from 'shared/services/local-storage-service';
import './style.scss';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import moment from 'moment';
import { toJS } from 'mobx';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { dataStore } from 'features/common/stores';
import { DispatchScreenDataStore } from '../stores/dispatch-screen-store';
import { dispatchScreenDataStore } from '../stores';
import { getDriverColDef } from './dispatch-screen-col-def';
import { boards, driverConfigBoards } from '../dispatchScreen-constants';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { uiService } from 'shared/services/ui-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface DispatchDetailsPopupProps {
    dispatchScreenDataStore: DispatchScreenDataStore;
    month: any;
    year: any;
    handleClose: any
}

interface DriverDetailsPopupState {
    selectedBoard: any;
    startDate: any;
    endDate: any;
    selectedRow: any[];
    additional: any[];
}


@observer
export class DriverConfigPopupComponent extends Component<DispatchDetailsPopupProps, DriverDetailsPopupState> {
    selectedBoard: any = 'North';
    fromDate: any = '';
    toDate: any = '';
    startDate: any = '';
    from: any;
    endDate: any = '';
    to: any;
    isSaveDisabled: boolean = false;
    driverData = [] as any;
    constructor(props) {
        super(props);
        this.state = {
            selectedBoard: 'All',
            startDate: '',
            endDate: '',
            selectedRow: [{ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North', isEditDisabled: false, isDeleted: false }],
            additional: []
        }
        dataStore.setModuleName('DriverConfig');
    }

    handleBoardChange = (board, ind) => {
        if (dispatchScreenDataStore.dataToBeDeleted.length === 1)
            dispatchScreenDataStore.selectedRow[ind].Board = board.currentTarget.value
        else dispatchScreenDataStore.additionalRow[ind].Board = board.currentTarget.value

        this.setState({ selectedRow: dispatchScreenDataStore.selectedRow });
    }

    handleTypeChange = type => {
        dispatchScreenDataStore.filterDriverBoardData(type.currentTarget.value);
        this.setState({ selectedBoard: type.currentTarget.value });
    }


    async componentDidMount(): Promise<void> {
        await dispatchScreenDataStore.getDriverBoardData();
        dispatchScreenDataStore.filterDriverBoardData(this.state.selectedBoard);
        // this.driverData = dispatchScreenDataStore.driverBoardData;
        // this.setState({ selectedBoard: 'All' });
    }

    render(): React.ReactNode {
        const { handleClose } = this.props;
        return (
            <div className="modal" id="load-modal">
                <div id="load-modal-content">
                    <div className="modal-body" id="load-modal-body">
                        <div id="alert-dialog-title" className="dialog-container">
                            <div style={{ padding: '10px 20px', fontSize: 'large', fontWeight: 'bold', display:'flex' }}>
                                Driver Configuration
                                <div className="react-select-container" style={{ fontWeight: 'normal'}}>
                                    Select Board Type :{' '}
                                    <select
                                        name="region"
                                        value={this.state.selectedBoard}
                                        className="react-select-style"
                                        onChange={this.handleTypeChange}
                                    >
                                        {driverConfigBoards.map(region => (
                                            <option key={region.ID} value={region.Value}>
                                                {region.Value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            
                            <button onClick={handleClose} className="popover-close-button">
                                X
                            </button>
                        </div>
                        <Container fluid>
                            <Row style={{ padding: '10px 20px' }}>
                                <div>
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </div>
                            </Row>
                            <Row>

                                <Col xl={9}>
                                    <AgGridComponent gridConfig={this.getGridDriverConfig()} />
                                </Col>
                                <Col xl={3}>
                                    <Row>
                                        <Col>Start</Col>
                                        <Col>End</Col>
                                        <Col>Board</Col>
                                        <Col></Col>
                                    </Row>
                                    {dispatchScreenDataStore.dataToBeDeleted.length === 1 &&
                                        dispatchScreenDataStore.selectedRow.map((data, ind) => {
                                            return !data.isDeleted && (
                                                <Row>
                                                    <Col style={{ paddingLeft: '5px', paddingRight: '0px' }}>
                                                        <div className="start-container">
                                                            <DayPickerInput
                                                                ref={el => (data.fromDate = el)}
                                                                dayPickerProps={{
                                                                    month: new Date(dispatchScreenDataStore.nominationYear, dispatchScreenDataStore.nominationMonth, 1),
                                                                    canChangeMonth: false,
                                                                    className: 'day-picker',
                                                                    disabledDays: this.disabledDatesInCalendarInput(dispatchScreenDataStore.selectedRow, ind)
                                                                }}
                                                                value={
                                                                    data.StartDate !== ''
                                                                        ? moment(data.StartDate)
                                                                            .format('YYYY-MM-DD')
                                                                            .toString()
                                                                        : ''
                                                                }
                                                                inputProps={{
                                                                    style: {
                                                                        width: '100%',
                                                                        height: '40px',
                                                                        border: dispatchScreenDataStore.dataToBeDeleted.length > 0 ? '1px solid #1eb2f3' : '1px solid red',
                                                                        borderRadius: 5,
                                                                        textAlign: 'center',
                                                                        fontSize: 10
                                                                    },
                                                                    disabled: dispatchScreenDataStore.dataToBeDeleted.length === 0 || data.isEditDisabled,
                                                                    value:
                                                                        data.StartDate !== ''
                                                                            ? moment(data.StartDate)
                                                                                .format('YYYY-MM-DD')
                                                                                .toString()
                                                                            : '',
                                                                    placeholder: 'yyyy-mm-dd'
                                                                }}
                                                                onDayChange={(date, modifiers) => {
                                                                    if (!modifiers.disabled) {
                                                                        data.StartDate = date.toISOString().slice(0, 10);
                                                                        this.setState({ selectedRow: dispatchScreenDataStore.selectedRow });
                                                                        data.from = date;
                                                                        this.isSaveDisabled = false;
                                                                        if (!data.EndDate) {
                                                                            data.toDate.getInput().focus();
                                                                        } else if (this.checkExistingPlansInBetweenDates(date, data.EndDate, ind)) {
                                                                            data.toDate.getInput().focus();
                                                                            this.setState({ endDate: data.EndDate });
                                                                            uiService.toastService.info(
                                                                                'Some leave plans are already assigned in between dates. Please delete them before creating new.'
                                                                            );
                                                                            this.isSaveDisabled = true
                                                                        }
                                                                        else if (data.StartDate > data.EndDate) {
                                                                            data.toDate.getInput().focus();
                                                                        } else {
                                                                            data.toDate.getInput().focus();
                                                                        }
                                                                    }
                                                                }}

                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col style={{ paddingLeft: '5px', paddingRight: '0px' }}>
                                                        <div className="end-container">
                                                            <DayPickerInput
                                                                ref={el => (data.toDate = el)}
                                                                dayPickerProps={{
                                                                    month: new Date(dispatchScreenDataStore.nominationYear, dispatchScreenDataStore.nominationMonth, 1),
                                                                    canChangeMonth: false,
                                                                    className: 'day-picker',
                                                                    disabledDays: this.disabledDatesInCalendarInput(dispatchScreenDataStore.selectedRow, ind)
                                                                }}
                                                                value={
                                                                    data.EndDate !== ''
                                                                        ? moment(data.EndDate)
                                                                            .format('YYYY-MM-DD')
                                                                            .toString()
                                                                        : ''
                                                                }
                                                                inputProps={{
                                                                    style: {
                                                                        width: '100%',
                                                                        height: '40px',
                                                                        border: dispatchScreenDataStore.dataToBeDeleted.length > 0 ? '1px solid #1eb2f3' : '1px solid red',
                                                                        borderRadius: 5,
                                                                        textAlign: 'center',
                                                                        fontSize: 10
                                                                    },
                                                                    disabled: dispatchScreenDataStore.dataToBeDeleted.length === 0 || data.isEditDisabled,
                                                                    value:
                                                                        data.EndDate !== ''
                                                                            ? moment(data.EndDate)
                                                                                .format('YYYY-MM-DD')
                                                                                .toString()
                                                                            : '',
                                                                    placeholder: 'yyyy-mm-dd'
                                                                }}
                                                                onDayChange={(date, modifiers) => {
                                                                    if (!modifiers.disabled) {
                                                                        data.EndDate = date.toISOString().slice(0, 10);
                                                                        this.setState({ selectedRow: dispatchScreenDataStore.selectedRow });
                                                                        data.to = date;
                                                                        this.isSaveDisabled = false
                                                                        if (!data.StartDate) {
                                                                            data.fromDate.getInput().focus();
                                                                        } else if (this.checkExistingPlansInBetweenDates(data.StartDate, date, ind)) {
                                                                            data.toDate.getInput().focus();
                                                                            data.EndDate = '';
                                                                            this.setState({ endDate: data.EndDate });
                                                                            uiService.toastService.info(
                                                                                'Some leave plans are already assigned in between dates. Please delete them before creating new.'
                                                                            );
                                                                            this.isSaveDisabled = true
                                                                        } else if (data.StartDate > data.EndDate) {
                                                                            data.fromDate.getInput().focus();
                                                                            uiService.toastService.info(
                                                                                'StartDate cannot be greater than EndDate'
                                                                            );
                                                                        }
                                                                    }
                                                                }}

                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col style={{ paddingLeft: '5px', paddingRight: '0px' }}>
                                                        <div>
                                                            <select
                                                                name="region"
                                                                value={data.Board}
                                                                style={{ 'width': '100%', height: '40px', border: '1px solid #1eb2f3', borderRadius: 5, fontSize: 12, textAlign: 'center' }}
                                                                onChange={(board) => {
                                                                    this.handleBoardChange(board, ind)
                                                                }}
                                                                disabled={dispatchScreenDataStore.dataToBeDeleted.length === 0 || data.isEditDisabled}
                                                            >
                                                                {boards.map(region => (
                                                                    <option key={region.ID} value={region.Name}>
                                                                        {region.Name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col style={{ paddingLeft: '5px', paddingRight: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {data.isEditDisabled && (
                                                            <div className="icon-div">

                                                                <FontAwesomeIcon
                                                                    icon="trash"
                                                                    size="xs"
                                                                    onClick={() => {
                                                                        this.deleteItem(ind);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {data.isEditDisabled && (
                                                            <div className="icon-div">
                                                                <FontAwesomeIcon
                                                                    icon="edit"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.editItem(ind);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {!data.isEditDisabled && data.MappingID && (
                                                            <div className="icon-div">
                                                                <FontAwesomeIcon
                                                                    icon="times"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.cancelEdit(ind);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {!data.isEditDisabled && !data.MappingID && (
                                                            <div className="icon-div">
                                                                <FontAwesomeIcon
                                                                    icon="times"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.removeEdit(ind);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                    {(dispatchScreenDataStore.dataToBeDeleted.length > 1 || dispatchScreenDataStore.dataToBeDeleted.length === 0) &&
                                        dispatchScreenDataStore.additionalRow.map((data, ind) => {
                                            return !data.isDeleted && (

                                                <Row>
                                                    <Col style={{ paddingLeft: '5px', paddingRight: '0px' }}>
                                                        <div className="start-container">
                                                            <DayPickerInput
                                                                ref={el => (data.fromDate = el)}
                                                                dayPickerProps={{
                                                                    month: new Date(dispatchScreenDataStore.nominationYear, dispatchScreenDataStore.nominationMonth, 1),
                                                                    canChangeMonth: false,
                                                                    className: 'day-picker',
                                                                    disabledDays: this.disabledDatesInCalendarInput(dispatchScreenDataStore.selectedRow, ind)
                                                                }}
                                                                value={
                                                                    data.StartDate !== ''
                                                                        ? moment(data.StartDate)
                                                                            .format('YYYY-MM-DD')
                                                                            .toString()
                                                                        : ''
                                                                }
                                                                inputProps={{
                                                                    style: {
                                                                        width: '100%',
                                                                        height: '40px',
                                                                        border: dispatchScreenDataStore.dataToBeDeleted.length > 0 ? '1px solid #1eb2f3' : '1px solid red',
                                                                        borderRadius: 5,
                                                                        textAlign: 'center',
                                                                        fontSize: 10
                                                                    },
                                                                    disabled: dispatchScreenDataStore.dataToBeDeleted.length === 0 || data.isEditDisabled,
                                                                    value:
                                                                        data.StartDate !== ''
                                                                            ? moment(data.StartDate)
                                                                                .format('YYYY-MM-DD')
                                                                                .toString()
                                                                            : '',
                                                                    placeholder: 'yyyy-mm-dd'
                                                                }}
                                                                onDayChange={(date, modifiers) => {
                                                                    if (!modifiers.disabled) {
                                                                        data.StartDate = date.toISOString().slice(0, 10);
                                                                        this.setState({ selectedRow: dispatchScreenDataStore.selectedRow });
                                                                        data.from = date;
                                                                        if (!data.EndDate) {
                                                                            data.toDate.getInput().focus();
                                                                        } else if (this.checkExistingPlansInBetweenDates(date, data.EndDate, ind)) {
                                                                            data.toDate.getInput().focus();
                                                                            this.setState({ selectedRow: dispatchScreenDataStore.selectedRow });
                                                                            uiService.toastService.info(
                                                                                'Some leave plans are already assigned in between dates. Please delete them before creating new.'
                                                                            );
                                                                        }
                                                                        else if (data.StartDate > data.EndDate) {
                                                                            data.toDate.getInput().focus();
                                                                        } else {
                                                                            data.toDate.getInput().focus();
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col style={{ paddingLeft: '5px', paddingRight: '0px' }}>
                                                        <div className="end-container">
                                                            <DayPickerInput
                                                                ref={el => (data.toDate = el)}
                                                                dayPickerProps={{
                                                                    month: new Date(dispatchScreenDataStore.nominationYear, dispatchScreenDataStore.nominationMonth, 1),
                                                                    canChangeMonth: false,
                                                                    className: 'day-picker',
                                                                    disabledDays: this.disabledDatesInCalendarInput(dispatchScreenDataStore.selectedRow, ind)
                                                                }}
                                                                value={
                                                                    data.EndDate !== ''
                                                                        ? moment(data.EndDate)
                                                                            .format('YYYY-MM-DD')
                                                                            .toString()
                                                                        : ''
                                                                }
                                                                inputProps={{
                                                                    style: {
                                                                        width: '100%',
                                                                        height: '40px',
                                                                        border: dispatchScreenDataStore.dataToBeDeleted.length > 0 ? '1px solid #1eb2f3' : '1px solid red',
                                                                        borderRadius: 5,
                                                                        textAlign: 'center',
                                                                        fontSize: 10
                                                                    },
                                                                    disabled: dispatchScreenDataStore.dataToBeDeleted.length === 0 || data.isEditDisabled,
                                                                    value:
                                                                        data.EndDate !== ''
                                                                            ? moment(data.EndDate)
                                                                                .format('YYYY-MM-DD')
                                                                                .toString()
                                                                            : '',
                                                                    placeholder: 'yyyy-mm-dd'
                                                                }}
                                                                onDayChange={(date, modifiers) => {
                                                                    if (!modifiers.disabled) {
                                                                        data.EndDate = date.toISOString().slice(0, 10);
                                                                        this.setState({ selectedRow: dispatchScreenDataStore.selectedRow });
                                                                        data.to = date;
                                                                        this.isSaveDisabled = false
                                                                        if (!data.StartDate) {
                                                                            data.fromDate.getInput().focus();
                                                                        } else if (this.checkExistingPlansInBetweenDates(data.StartDate, date, ind)) {
                                                                            data.toDate.getInput().focus();
                                                                            data.EndDate = '';
                                                                            this.setState({ endDate: data.EndDate });
                                                                            uiService.toastService.info(
                                                                                'Some leave plans are already assigned in between dates. Please delete them before creating new.'
                                                                            );
                                                                            this.isSaveDisabled = true
                                                                        } else if (data.StartDate > data.EndDate) {
                                                                            data.fromDate.getInput().focus();
                                                                            uiService.toastService.info(
                                                                                'StartDate cannot be greater than EndDate'
                                                                            );
                                                                            this.isSaveDisabled = true
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col style={{ paddingLeft: '5px', paddingRight: '0px' }}>
                                                        <div>
                                                            <select
                                                                name="region"
                                                                value={data.Board}
                                                                style={{ 'width': '100%', height: '40px', border: '1px solid #1eb2f3', borderRadius: 5, fontSize: 12, textAlign: 'center' }}
                                                                onChange={(board) => {
                                                                    this.handleBoardChange(board, ind)
                                                                }}
                                                                disabled={dispatchScreenDataStore.dataToBeDeleted.length === 0 || data.isEditDisabled}
                                                            >
                                                                {boards.map(region => (
                                                                    <option key={region.ID} value={region.Name}>
                                                                        {region.Name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col style={{ paddingLeft: '5px', paddingRight: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {data.isEditDisabled && (
                                                            <div className="icon-div">

                                                                <FontAwesomeIcon
                                                                    icon="trash"
                                                                    size="xs"
                                                                    onClick={() => {
                                                                        this.deleteItem(ind);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {data.isEditDisabled && (
                                                            <div className="icon-div">
                                                                <FontAwesomeIcon
                                                                    icon="edit"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.editItem(ind);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {!data.isEditDisabled && (
                                                            <div className="icon-div">
                                                                <FontAwesomeIcon
                                                                    icon="times"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.removeEdit(ind);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                    {/* )} */}
                                    <div className="additional-container">
                                        <button
                                            className={
                                                'additional-label'
                                            }
                                            onClick={() => {
                                                this.onAdditionalButtonClick();
                                            }}
                                        >
                                            {' '}
                                            + Add Additional Row
                                        </button>
                                    </div>
                                    <div>
                                        <CustomButton type={CustomButtonType.Submit} onClick={this.handleSave}
                                            disabled={dispatchScreenDataStore.dataToBeDeleted.length == 0 || this.dateCheck() || this.isSaveDisabled}>
                                            Save
                                        </CustomButton>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>{' '}
                </div>{' '}
            </div>
        );
    }

    componentDidUpdate() {
        dispatchScreenDataStore.agGridService.setAllFilters(localStorageService.get('DriverConfig_filter'))
    }

    getGridDriverConfig(): GridOptions {
        const rowData = dispatchScreenDataStore.driverBoardData;
        const columnDefs = getDriverColDef();
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
        };
        return dispatchScreenDataStore.agGridService.getGridConfig(DuplicateRecordsGridParams);
    }

    dateCheck = () => {
        let flag = false;
        dispatchScreenDataStore.selectedRow.map(list => {
            if (list.StartDate == '' || list.EndDate == '') {
                flag = true;
            }
        })
        return flag
    }

    handleRefresh = async () => {
        await dispatchScreenDataStore.getDriverBoardData();
        dispatchScreenDataStore.filterDriverBoardData(this.state.selectedBoard);
    }

    onAdditionalButtonClick = () => {
        const newadditionalrow = dispatchScreenDataStore.dataToBeDeleted.length === 1 ? dispatchScreenDataStore.selectedRow : dispatchScreenDataStore.additionalRow
        newadditionalrow.push({ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North' });

        if (dispatchScreenDataStore.dataToBeDeleted.length === 1) {
            dispatchScreenDataStore.selectedRow = newadditionalrow;
        } else {
            dispatchScreenDataStore.additionalRow = newadditionalrow;
        }

        this.setState({ selectedRow: newadditionalrow });
    }

    disabledDatesInCalendarInput = (selectedRow, id) => {
        console.log(selectedRow, id)
        const disableDatesAraay = [] as any;
        if (selectedRow && selectedRow.length > 0) {
            selectedRow.map((item, index) => {
                if (dispatchScreenDataStore.dataToBeDeleted.length === 1 && id !== index && !item.isDeleted) {
                    const start = parseInt(moment(new Date(item.StartDate.toString())).format('D'));
                    const end = parseInt(moment(new Date(item.EndDate.toString())).format('D'));
                    for (let i = 0; i <= end - start; i++) {
                        const date = moment(item.StartDate).add(i, 'days');
                        disableDatesAraay.push(new Date(date.toString()));
                    }
                } else if (dispatchScreenDataStore.dataToBeDeleted.length > 1) {
                    const start = parseInt(moment(new Date(item.StartDate.toString())).format('D'));
                    const end = parseInt(moment(new Date(item.EndDate.toString())).format('D'));
                    for (let i = 0; i <= end - start; i++) {
                        const date = moment(item.StartDate).add(i, 'days');
                        disableDatesAraay.push(new Date(date.toString()));
                    }
                }
            })
        }
        return disableDatesAraay;
    };
    checkExistingPlansInBetweenDates = (date1, date2, id) => {
        const appliedLeaveDates = this.disabledDatesInCalendarInput(dispatchScreenDataStore.selectedRow, id);
        let vacationPlanExist = false;
        const start = parseInt(moment(new Date(date1.toString())).format('D'));
        const end = parseInt(moment(new Date(date2.toString())).format('D'));
        for (let i = 0; i <= end - start; i++) {
            const date = moment(date1).add(i, 'days');
            if (appliedLeaveDates.filter(item => item.toString() === new Date(date.toString()).toString()).length > 0) {
                vacationPlanExist = true;
                break;
            }
        }
        return vacationPlanExist;
    };

    deleteItem = (index) => {
        if (dispatchScreenDataStore.dataToBeDeleted.length === 1)
            dispatchScreenDataStore.selectedRow[index].isDeleted = true;
        else dispatchScreenDataStore.additionalRow[index].isDeleted = true;

        this.setState({ selectedRow: dispatchScreenDataStore.selectedRow })
    }

    editItem = (index) => {
        if (dispatchScreenDataStore.dataToBeDeleted.length === 1)
            dispatchScreenDataStore.selectedRow[index].isEditDisabled = false;
        else dispatchScreenDataStore.additionalRow[index].isEditDisabled = false;

        this.setState({ selectedRow: dispatchScreenDataStore.selectedRow })
    }

    cancelEdit = (index) => {
        if (dispatchScreenDataStore.dataToBeDeleted.length === 1)
            dispatchScreenDataStore.selectedRow[index].isEditDisabled = true;
        else dispatchScreenDataStore.additionalRow[index].isEditDisabled = true;

        this.setState({ selectedRow: dispatchScreenDataStore.selectedRow })
    }

    removeEdit = (index) => {
        if (dispatchScreenDataStore.dataToBeDeleted.length === 1)
            dispatchScreenDataStore.selectedRow.splice(index, 1);
        else dispatchScreenDataStore.additionalRow.splice(index, 1);

        this.setState({ selectedRow: dispatchScreenDataStore.selectedRow })
    }

    handleSave = async () => {
        let result: any = [];
        let data: any = [];
        let dataset: any = [];
        if (dispatchScreenDataStore.dataToBeDeleted.length === 1) {
            dataset = dispatchScreenDataStore.selectedRow
        } else {
            dataset = dispatchScreenDataStore.additionalRow
        }
        dataset.forEach(element => {
            if (!element.isEditDisabled) {
                data.push({
                    StartDate: element.StartDate,
                    EndDate: element.EndDate,
                    IsNorth: element.Board === 'North' ? 1 : 0,
                    IsSouth: element.Board === 'South' ? 1 : 0,
                    IsCentral: element.Board === 'Central' ? 1 : 0,
                    IsNGLNorth: element.Board === 'NGL North' ? 1 : 0,
                    IsNGLSouth: element.Board === 'NGL South' ? 1 : 0,
                    IsPropane: element.Board === 'Propane' ? 1 : 0,
                    IsBrine: element.Board === 'Brine' ? 1 : 0,
                    DriverBoardID: element.MappingID ? element.MappingID : 0,
                    isDeleted: element.isDeleted
                })
            } else {
                data.push({
                    StartDate: element.StartDate,
                    EndDate: element.EndDate,
                    IsNorth: element.Board === 'North' ? 1 : 0,
                    IsSouth: element.Board === 'South' ? 1 : 0,
                    IsCentral: element.Board === 'Central' ? 1 : 0,
                    IsNGLNorth: element.Board === 'NGL North' ? 1 : 0,
                    IsNGLSouth: element.Board === 'NGL South' ? 1 : 0,
                    IsPropane: element.Board === 'Propane' ? 1 : 0,
                    IsBrine: element.Board === 'Brine' ? 1 : 0,
                    DriverBoardID: element.MappingID ? element.MappingID : 0,
                    isDeleted: element.isDeleted
                })
            }
        })
        dispatchScreenDataStore.dataToBeDeleted.forEach(ind => {
            /* eslint-disable */
            let res = dispatchScreenDataStore.dataToBeDeleted.length > 1 ? dispatchScreenDataStore.selectedRow.filter(x => x.DriverID === ind.DriverID) : []
            res.forEach((x) => {
                x.IsNorth = x.Board === 'North' ? 1 : 0,
                    x.IsSouth = x.Board === 'South' ? 1 : 0,
                    x.IsCentral = x.Board === 'Central' ? 1 : 0,
                    x.IsNGLNorth = x.Board === 'NGL North' ? 1 : 0,
                    x.IsNGLSouth = x.Board === 'NGL South' ? 1 : 0,
                    x.IsPropane = x.Board === 'Propane' ? 1 : 0,
                    x.IsBrine = x.Board === 'Brine' ? 1 : 0
            })
            result.push({
                Driver: ind.Driver,
                DriverID: ind.DriverID,
                Month: moment.months()[dispatchScreenDataStore.nominationMonth],
                Year: dispatchScreenDataStore.nominationYear,
                VacationPlan: [],
                CreatedBy: dispatchScreenDataStore.userID,
                DeletedRecords: [],
                AvailabilityProvided: 1,
                ModifiedBy: dispatchScreenDataStore.userID,
                DriverBoardMappingDTO: [...data, ...res]
            })
        })
        await dispatchScreenDataStore.updateDriverBoard(result);
        dispatchScreenDataStore.filterDriverBoardData(this.state.selectedBoard);
        // this.driverData = dispatchScreenDataStore.driverBoardData;
        this.setState({
            selectedRow: [{ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North' }]
        })
    }
}