import React, { Component } from 'react';
import { GridOptions } from 'ag-grid-community';
import { Col, Container, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { localStorageService } from 'shared/services/local-storage-service';
import './style.scss';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import Select from 'react-select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { dataStore } from 'features/common/stores';
import { DispatchScreenDataStore } from '../stores/dispatch-screen-store';
import { dispatchScreenDataStore } from '../stores';
import { nominationBoardColDef } from './dispatch-screen-col-def';
interface DispatchDetailsPopupProps {
    dispatchScreenDataStore: DispatchScreenDataStore;
    month: any;
    year: any;
    locationData: any;
    handleClose: any
}
@observer
export class NominationConfigPopUpComponent extends Component<DispatchDetailsPopupProps> {

    constructor(props) {
        super(props);
        this.state = {
            selectedLocation: []
        }
        dataStore.setModuleName('NominationConfig');
    }

    handleDataTypeChange = (option) => {
        if (option) {
            dispatchScreenDataStore.selectedLocation = option;
            this.setState({ selectedLocation: dispatchScreenDataStore.selectedLocation })
        } else {
            dispatchScreenDataStore.selectedLocation = [];
            this.setState({ selectedLocation: dispatchScreenDataStore.selectedLocation })
        }
    }

    async componentDidMount(): Promise<void> {
        await dispatchScreenDataStore.getDispatchBoardData();
    }

    render(): React.ReactNode {
        const { handleClose, locationData, month, year } = this.props;
        let locations = locationData.filter(o1 => !dispatchScreenDataStore.dispatchBoardData.some(o2 => o1.Consignor === o2.AX_Origin && o1.Consignee === o2.AX_Destination));
        return (
            <div className="modal" id="load-modal">
                <div id="load-modal-content">
                    <div className="modal-body" id="load-modal-body">
                        <div id="alert-dialog-title" className="dialog-container">
                            <div style={{ padding: '10px 20px', fontSize: 'large', fontWeight: 'bold' }}>
                                Nomination Configuration - {dispatchScreenDataStore.selectedBoard} Board
                            </div>
                            <button onClick={handleClose} className="popover-close-button">
                                X
                            </button>
                        </div>
                        <Container fluid>
                            <Row style={{ padding: '10px 20px' }}>
                                Select Locations:
                                <div className="reportmanagement__type" style={{ padding: '0 0 0 48px', width: '80%' }}>
                                    <Select
                                        value={dispatchScreenDataStore.selectedLocation}
                                        closeMenuOnSelect={false}
                                        closeMenuOnScroll={true}
                                        placeholder="Select Locations"
                                        options={locations}
                                        onChange={this.handleDataTypeChange}
                                        isMulti={true}
                                    />
                                </div>
                            </Row>
                            <Row style={{ padding: '10px 20px' }}>
                                <div>
                                    <CustomButton type={CustomButtonType.Submit} onClick={this.handleAddConfiguration} disabled={dispatchScreenDataStore.selectedLocation.length === 0}>
                                        Add
                                    </CustomButton>
                                    <CustomButton type={CustomButtonType.Submit} onClick={this.handleDeleteConfiguration} disabled={dispatchScreenDataStore.dataToBeDeleted.length === 0}>
                                        Delete
                                    </CustomButton>
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <AgGridComponent gridConfig={this.getGridNominationConfig()} />
                                </Col>
                            </Row>
                        </Container>
                    </div>{' '}
                </div>{' '}
            </div>
        );
    }

    componentDidUpdate() {
        dispatchScreenDataStore.agGridService.setAllFilters(localStorageService.get('NominationConfig_filter'))
    }

    getGridNominationConfig(): GridOptions {
        const rowData = dispatchScreenDataStore.dispatchBoardData;
        const columnDefs = nominationBoardColDef
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
        };
        return dispatchScreenDataStore.agGridService.getGridConfig(DuplicateRecordsGridParams);
    }

    handleRefresh = async () => {
        await dispatchScreenDataStore.getDispatchBoardData();
    }

    handleAddConfiguration = () => {
        let reqbody: any = [];
        dispatchScreenDataStore.selectedLocation.forEach(ind => {
            reqbody.push({
                ID: 0,
                BoardMapped: dispatchScreenDataStore.selectedBoard,
                IsDeleted: false,
                AX_Origin: ind.Consignor,
                AX_Destination: ind.Consignee,
                DataSource: ['NGL North','NGL South', 'Propane', 'Brine'].includes(dispatchScreenDataStore.selectedBoard) ? 'NGL' : 'Crude',
                NominationMonth: dispatchScreenDataStore.nominationMonth + 1,
                NominationYear: dispatchScreenDataStore.nominationYear,
                CreatedBy: dispatchScreenDataStore.userID
            })
        })
        dispatchScreenDataStore.updateDispatchBoard(reqbody);
    }

    handleDeleteConfiguration = () => {
        dispatchScreenDataStore.deleteRecords()
    }
}