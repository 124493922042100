import moment from 'moment';
import weekDayHeader from './week-day-header';
import { dispatchScreenDataStore } from '../stores';
import { NominationCheckBoxCellRenderer } from './nomination-config-checkbox';
import { DriverCheckBoxCellRenderer } from './driver-config-checkbox';
import { accountStore } from 'features/account/stores';
const generateDate = date => {
    const { nominationMonth, nominationYear } = dispatchScreenDataStore;
    return moment(new Date(nominationYear, nominationMonth, date).toISOString()).format('MM/DD/YYYY');
};
const daysInMonth = (month, year) => {
    // Use 1 for January, 2 for February, etc.
    return new Date(year, month, 0).getDate();
};
const checkDispatcherRole = () => {
    let access = false;
    const currentUserRoles = accountStore.userRoles;
    // if (currentUserRoles.includes('ptsdispatcher') || currentUserRoles.includes('ptstruckingmanager') || currentUserRoles.includes('ptssysadmin')) {
    if (currentUserRoles.includes('ptsdispatcher') || currentUserRoles.includes('ptssysadmin')) {
        access = true;
    }
    return access;
}

export const getDispatchColDef = () => {
    const dispatchBoardColDef: any = [];
    dispatchBoardColDef.push(
        {
            headerName: 'Carrier Company',
            field: 'CompanyName',
            rowGroup: true,
            hide: true,
            width: 95
        },
        {
            headerName: 'Driver Name',
            field: 'Driver',
            colId: 'Driver',
            width: 65,
        }
    );

    const getColor = status => {
        if (status === 'Pending' || status === 'Recalled' || status === 'Cancelled By Driver' || status === 'Rejected By Driver' ||
            status === 'Rejected By Carrier' || status === 'Cancelled By Carrier' || status === 'Recalled By Carrier') {
            return 'color: purple'
        }
        else if (status === 'Completed') {
            return 'color: grey'
        } else {
            return 'color: green'
        }
    }
    for (let i = 0; i < daysInMonth(dispatchScreenDataStore.nominationMonth + 1, dispatchScreenDataStore.nominationYear); i++) {
        const headerDate = moment(new Date(dispatchScreenDataStore.nominationYear, dispatchScreenDataStore.nominationMonth, i + 1).toISOString()).format('MM/DD/YYYY')
        const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const dayOfWeek = daysOfWeek[new Date(headerDate).getDay()];
        dispatchBoardColDef.push({
            headerComponentFramework: weekDayHeader,
            headerComponentParams: { date: i + 1 },
            field: `${dayOfWeek} ${String(i + 1).padStart(2, "0")}`,
            filter: true,
            width: 58,
            colId: i,
            autoHeight: true,
            cellClass: params => {
                if (!params.node.group) {
                    for (let ind = 0; ind < params.data.DataResponse.length; ind++) {
                        if (moment(params.data.DataResponse[ind].Date).format('MM/DD/YYYY') === generateDate(i + 1)) {
                            let DataResponse = params.data.DataResponse[ind];
                            if (DataResponse.BoardMapped === dispatchScreenDataStore.selectedBoard
                                && DataResponse.Availability === 'UnAvailable') {
                                return 'trailer-ques-bgColor-orange';
                            } else if (
                                DataResponse.BoardMapped === dispatchScreenDataStore.selectedBoard && DataResponse.IsAvailability === 1
                            ) {
                                return 'trailer-ques-bgColor-available';
                            } else if (
                                params.data.DriverID === null) {
                                return 'trailer-ques-bgColor-available';
                            } else return 'trailer-ques-bgColor-gray';
                        }
                    }
                    if(i+1 < moment(dispatchScreenDataStore.startDate).date() || i+1 > moment(dispatchScreenDataStore.endDate).date()){
                        console.log(i+1);
                        return 'trailer-ques-bgColor-gray';
                    }
                }
                else {
                    return 'cell-wrap';
               }
            },
            cellRenderer: params => {
                if (!params.node.group) {
                    for (let ind = 0; ind < params.data.DataResponse.length; ind++) {
                        if (moment(params.data.DataResponse[ind].Date).format('MM/DD/YYYY') === generateDate(i + 1)) {
                            let Loads = params.data.DataResponse[ind].LoadNumber ? params.data.DataResponse[ind].LoadNumber.split(',') : [];
                            let status = params.data.DataResponse[ind].Status ? params.data.DataResponse[ind].Status.split(',') : [];
                            // const html = Loads.map((item, index) => {
                            //     return `<span style="` + getColor(status[index]) + `">${Loads[index] ? Loads[index] : ''}</span>`
                            // }).join(',')
                            const html = Loads.map((item, index) => {
                                return `<span style="` + getColor(status[index]) + `">${Loads[index] ? Loads[index] : ''}</span>`
                            }).join('<br/>')
                            return html
                        }
                    }
                }
                else {
                    if (checkDispatcherRole()) {
                        const dispatcherNotes = dispatchScreenDataStore.dispatchBoardTickets
                            .find(data => data.DriverID === null && data.Driver === null && data.CompanyName === params.node.key)?.DataResponse
                            ?.find(response => moment(response.Date).format('MM/DD/YYYY') === generateDate(i + 1))?.DispatcherNotes;
                        if (dispatcherNotes) {
                            const formattedNotes = dispatcherNotes.replace(/(.{50})/g, '$1<br>');
                            const html = `<span style="display: block; text-align: center;">${formattedNotes}</span>`;
                            return html;
                         }
                        return `<span style="display: block; text-align: center;"></span>`;   
                    }
                }
            },
            valueGetter: params => {
                if (!params.node.group) {
                    for (let ind = 0; ind < params.data.DataResponse.length; ind++) {
                        if (moment(params.data.DataResponse[ind].Date).format('MM/DD/YYYY') === generateDate(i + 1)) {
                            return params.data.DataResponse[ind].LoadNumber
                        }
                    }
                }
            }
        });
    }
    return dispatchBoardColDef;
}

export const nominationBoardColDef: any = [
    {
        headerName: 'Select',
        field: 'IsDeleted',
        colId: 'IsDeleted',
        cellRendererFramework: NominationCheckBoxCellRenderer,
        width: 95
    },
    {
        headerName: 'Consignor',
        field: 'AX_Origin',
        colId: 'AX_Origin',
        width: 95
    },
    {
        headerName: 'Consignee',
        field: 'AX_Destination',
        colId: 'AX_Destination',
        width: 95
    },
    {
        headerName: 'Data Source',
        field: 'DataSource',
        colId: 'DataSource',
        width: 95
    }
]

export const getDriverColDef = () => {
    const driverBoardColDef: any = [
        {
            headerName: 'Select',
            field: 'IsSelected',
            colId: 'IsSelected',
            cellRendererFramework: DriverCheckBoxCellRenderer,
            width: 70
        },
        {
            headerName: 'Driver Name',
            field: 'Driver',
            colId: 'Driver',
            width: 65,
    
        }
    ]
    for (let i = 0; i < daysInMonth(dispatchScreenDataStore.nominationMonth + 1, dispatchScreenDataStore.nominationYear); i++) {
        const headerDate = moment(new Date(dispatchScreenDataStore.nominationYear, dispatchScreenDataStore.nominationMonth, i + 1).toISOString()).format('MM/DD/YYYY')
        const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const dayOfWeek = daysOfWeek[new Date(headerDate).getDay()];
        driverBoardColDef.push({
            headerComponentFramework: weekDayHeader,
            headerComponentParams: { date: i + 1 },
            filter: true,
            field: `${dayOfWeek} ${String(i + 1).padStart(2, "0")}`,
            width: 58,
            colId: i,
            valueGetter: params => {
                if (!params.node.group) {
                    for (let ind = 0; ind < params.data?.DataResponse?.length; ind++) {
                        if (moment(params.data.DataResponse[ind].Date).format('MM/DD/YYYY') === generateDate(i + 1)) {
                            return params.data.DataResponse[ind].Board
                        }
                    }
                }
            }
        });
    }
    return driverBoardColDef;
}
