import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { BroadCastPopUp } from './broadCastPopup';
import { Confirm } from './confirm';
import { EmailPopUp } from './emailPopup';
import { LocationPopUp } from './locationPopup';
import { LocationMapingPopUp } from './locationMapingPopup';
import { UserRolePopup } from './userRolePopup';
import { ExcelPopup } from './excelPopup';
import { LoadPopup } from './loadPopup';
import { NominationLocationPopUp } from './nominationLocationPopup';
import { LocationAXPopUp } from './locationAXPopUp';
import { ApportionmentPopUp } from './apportionmentPopup';
import { ApportionmentApplyPopUp } from './apportionmentApplyPopup';
import { BulkAssignPopUp } from './bulkAssignPopup';
import { GenerateLoadPopup } from './generateLoadpopup';
import { ConfirmBulkAssignPopUp } from './confirmBulkAssignPopup';
import { DriverPopup } from './DriverPopup';
import { TrailerPopup } from './TrailerPopup';
import { AdUpdatePopup } from './adUpdatePopup';
import { ExcelValidatorPopup } from './excelValidatorPopup';
import { PlannedLoadsPopup } from './plannedLoadsPopup';
import { DispatcherNotesPopup } from './dispatcherNotesPopup';

export class ConfirmService {
    showConfirmDialog(proceedCallback: () => void, primaryText: string, secondaryText: string) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirm
                        onClose={onClose}
                        primaryText={primaryText}
                        secondaryText={secondaryText}
                        onSuccessClick={proceedCallback}
                    />
                );
            }
        });
    }
    showEmailPopup(callBack: (email) => void, msgData: any = [], mail = false, reply = false, ccname = false) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <EmailPopUp onClose={onClose} onSuccessClick={callBack} mail={mail} msg={msgData} reply={reply} ccname={ccname} />
                );
            }
        });
    }

    showApportionmentPopup(callBack: (email) => void) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <ApportionmentPopUp onClose={onClose} onSuccessClick={callBack} />;
            }
        });
    }

    showApportionmentApplyPopup(callBack: (email) => void) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <ApportionmentApplyPopUp onClose={onClose} onSuccessClick={callBack} />;
            }
        });
    }

    showBroadCastPopup(callBack: () => void, msgData: any = []) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <BroadCastPopUp onClose={onClose} data={msgData}></BroadCastPopUp>;
            }
        });
    }

    showUserRolePopup(callBack: (email) => void, roleData: any = [], role: any = '') {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <UserRolePopup onClose={onClose} onSuccessClick={callBack} data={roleData} selectedRole = {role}/>;
            }
        });
    }

    showExcelPopup(callBack: (email) => void, excelData: any = []) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <ExcelPopup onClose={onClose} onSuccessClick={callBack} data={excelData} />;
            }
        });
    }

    showExcelValidatorPopup(callBack: () => void, response, uploadFlag, maxRowsReached, length, errorMessage ) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <ExcelValidatorPopup onClose = {onClose} response={response} uploadFlag={uploadFlag} maxRowsReached={maxRowsReached} length={length} errorMessage={errorMessage} />
            }
        })
    }

    showLoadPopup(callBack: (recurrence,driver, company, data, selectedLoads, dispatchType) => void, startDate, endDate, loads, driverData: any = [], companyData: any = [], selectedLoads: any = []) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <LoadPopup
                        onClose={onClose}
                        onSuccessClick={callBack}
                        startDate={startDate}
                        endDate={endDate}
                        loads={loads}
                        msg={driverData}
                        companyInfo={companyData}
                        selectedLoads={selectedLoads}
                    />
                );
            }
        });
    }

    showGenerateLoadPopup(callBack: (type, volume, loadType, manualType?) => void, product, isApplied) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <GenerateLoadPopup onClose={onClose} onSuccessClick={callBack} product={product} isApplied={isApplied}/>;
            }
        });
    }

    showAdUpdatePopup(callBack: (email) => void, onDeleteClick, roleData: any = [], role: any = '', userInfo: any) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <AdUpdatePopup onClose={onClose} onAddClick={callBack} data={roleData} selectedRole = {role} onDeleteClick={onDeleteClick} userInfo = {userInfo}/>;
            }
        });
    }

    showLocationPopup(
        callBack: (consginorAXID, consgineeAXID, consginorRAID, consgineeRAID, loadSize, region) => void,
        operatorData: any = [], regionData: any = [],
        locationDataSerivice: any, locationData: any = [],
        raConfig: any = []
    ) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <LocationPopUp
                        onClose={onClose}
                        onSuccessClick={callBack}
                        msg={operatorData}
                        region={regionData}
                        locationDataSerivice={locationDataSerivice}
                        location={locationData}
                        raConfig={raConfig}
                    />
                );
            }
        });
    }

    showBulkAssignPopup(callBack: (selectedDispatchType, driver, trailer, buttonName) => void, driverOrCarrierData: any = [], trailerData: any, selectedDispatchType) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <BulkAssignPopUp
                        onClose={onClose}
                        onSuccessClick={callBack}
                        msg={driverOrCarrierData}
                        trailerData={trailerData}
                        selectedDispatchType={selectedDispatchType}
                    />
                );
            }
        });
    }

    confirmBulkAssignPopup(callBack: (buttonName) => void) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmBulkAssignPopUp
                        onClose={onClose}
                        onSuccessClick={callBack}
                    />
                );
            }
        });
    }

    showLocationMapingPopup(
        callBack: (sourceLoc, sourceLocCode, sourceLocLSD, AXLoc, Datasource, truckedByLocation) => void,
        locationData: any = [],
        locationMapingDataSerivice: any
    ) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <LocationMapingPopUp
                        onClose={onClose}
                        onSuccessClick={callBack}
                        msg={locationData}
                        locationMapingDataSerivice={locationMapingDataSerivice}
                    />
                );
            }
        });
    }
    showNominationLocationPopup(callBack: (sourceLoc) => void, locationData: any = []) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <NominationLocationPopUp onClose={onClose} onSuccessClick={callBack} msg={locationData} />;
            }
        });
    }

    showLocationAXPopup(callBack: (sourceLoc,type?) => void, locationData: any = [], dispatch: boolean = false, selectedTicket: any = []) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <LocationAXPopUp onClose={onClose} onSuccessClick={callBack} msg={locationData} dispatch={dispatch} selectedTickets={selectedTicket}/>;
            }
        });
    }

    showDriverPopup(callBack: (driverName) => void, driverData: any = []) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <DriverPopup onClose={onClose} onSuccessClick={callBack} msg={driverData} />;
            }
        });
    }

    showTrailerPopup(callBack: (trailerNumber) => void, trailerData: any = []) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <TrailerPopup onClose={onClose} onSuccessClick={callBack} msg={trailerData} />;
            }
        });
    }

    showPlannedLoadsPopup(callBack: (sourceLoc,type?) => void, plannedLoads: any = [], loads: any = []) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <PlannedLoadsPopup onClose={onClose} onSuccessClick={callBack} plannedLoads={plannedLoads} loads={loads}/>;
            }
        });
    }

    showDispatcherNotesPopup(callBack: (note: string) => void, dispatcherNote: any) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <DispatcherNotesPopup onClose={onClose} onSuccessClick={callBack} dispatcherNote = {dispatcherNote}/>;
            }
        });
    }

    showConfirmWithCustomComponent<P>(
        callBack: (data) => void,
        Component: React.ComponentClass<P>,
        props: P,
        classNameStyle: string
    ) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={classNameStyle}>
                        <Component {...props} onClose={onClose} onSuccessClick={callBack} />
                    </div>
                );
            }
        });
    }

    showMessageAlert(message: string) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm">
                        <div className="confirm__warning mt-10">
                            <i className="fa fa-exclamation-triangle confirm-warning"></i>
                        </div>
                        <div className="justify-content-center confirm__secondary-text mt-10">{message}</div>
                    </div>
                );
            }
        });
    }
}
