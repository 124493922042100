import React from 'react';
import { CustomButton, CustomButtonType } from '../custom-button/custom-button';

export interface DispatcherNotesProps {
    onSuccessClick: (note: string) => void;
    onClose: () => void;
    dispatcherNote: string;
}

export class DispatcherNotesPopup extends React.Component<DispatcherNotesProps> {
    state = {
        note: this.props.dispatcherNote ? this.props.dispatcherNote : '',
        showAlert: false,
        typingNotification: false,
    };

    handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const input = e.target.value;

        if (input.length > 250) {
            this.setState({ typingNotification: true });
        } else {
            this.setState({ typingNotification: false });
        }
        this.setState({ note: input });
    };

    handleSave = () => {
        if (this.state.note.length > 250) {
            this.setState({ showAlert: true });
        } else {
            this.props.onSuccessClick(this.state.note);
            this.props.onClose();
        }
    };

    render() {
        const { note, showAlert, typingNotification } = this.state;
        const { onClose } = this.props;
        return (
            <div className="dispatchernotespopup" style={{ padding: '20px', textAlign: 'center' }}>
                <h3 style={{ fontSize: '18px', marginBottom: '20px' }}>Enter your Comment</h3>
                {showAlert && (
                    <div className="alertBox">
                        <div className="error-message" style={{ marginTop: '40vh', width: '700px' }}>
                            <div>
                                <div style={{ padding: 5 }}>
                                    <b>Note:</b> Your comment exceeds <b>250 characters</b>. Please shorten it before saving.
                                </div>
                            </div>
                            <div style={{ paddingBottom: 10, paddingLeft: 265 }}>
                                <CustomButton
                                    type={CustomButtonType.Ok}
                                    onClick={() => this.setState({ showAlert: false })}
                                >
                                    OK
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                )}
                {typingNotification && (
                    <div
                        style={{
                            color: '#FF5733',
                            fontSize: '12px',
                            marginBottom: '10px',
                            textAlign: 'left',
                        }}
                    >
                        You have reached the maximum limit of 250 characters.
                    </div>
                )}
                <textarea
                    value={note}
                    onChange={this.handleNoteChange}
                    placeholder="Enter your comment here..."
                    style={{
                        width: '100%',
                        height: '100px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        fontSize: '14px',
                        padding: '10px',
                        marginBottom: '20px',
                    }}
                />
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <CustomButton
                        type={CustomButtonType.Ok}
                        onClick={this.handleSave}
                        disabled={note.length > 250}
                    >
                        Save
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                        Cancel
                    </CustomButton>
                </div>
            </div>
        );
    }
}